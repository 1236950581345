import { isUserAuthenticated } from "../helpers/authUtils";
import { IUserReducer } from "../contexts/interfaces/userInterfaces";
import dev from "../../core/enviroments";

export const proxyfiles = () => {
  const { fetch: originalFetch } = window;
  const originalWindowOpen = window.open;

  window.fetch = async (...args) => {
    let [resource, options] = args;
    if (resource?.toString().includes('uploads')) {
      const user = isUserAuthenticated(true);

      if (user?.isAutenticated || (!user?.isAutenticated && user?.data?.token)) {
        const dataJson: IUserReducer = isUserAuthenticated(true);
        options = options || {};
        options.headers = {
          ...options.headers,
          Authorization: dataJson.data?.token || ''
        };
      }
    }
    return await originalFetch(resource, options);
  };

  window.open = function (url?: string | URL | undefined, target?: string | undefined, features?: string | undefined): Window | null {
    let finalUrl: string | undefined;

    if (url && typeof url === 'string' && (url.includes('/uploads/') || url.includes('/plantillas/') || url.includes('/multimedia/'))) {
      // Verifica si la URL ya contiene el parámetro del token
      if (!url.includes(dev.TOKEN_PROXYFILES)) {
        const user = isUserAuthenticated(true);
        let tokenurl = '';

        if (user?.isAutenticated || (!user?.isAutenticated && user?.data?.token)) {
          const dataJson: IUserReducer = isUserAuthenticated(true);
          tokenurl = dataJson.data?.token || '';
        }

        const urlFrase = dev.TOKEN_PROXYFILES;
        // Usa '&' si la URL ya contiene '?', o '?' si no.
        const separator = url.includes('?') ? '&' : '?';
        finalUrl = `${url}${separator}${urlFrase}=${tokenurl}`;
      } else {
        finalUrl = url; // Mantiene la URL original si ya tiene el token
      }
    } else if (url instanceof URL) {
      finalUrl = url.toString();
    } else {
      finalUrl = url;
    }
    return originalWindowOpen(finalUrl, target, features);
  };
};